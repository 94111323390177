import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React, { useState } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import WhatsApp_Logo from './whatsapp.png';
import raecom from './raecom.jpg';
import './App.css';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

function InfoDeck() {
	return (
		<div className="card-deck">
			<AboutCard />
		</div>
	);
}

function ContactCard() {
	const { t } = useTranslation();
	const [ privacyButton, setPrivacy ] = useState(true);
	const [ name, setName ] = useState('');
	const [ email, setEmail ] = useState('');
	const [ company, setCompany ] = useState('');
	const [ phoneNumber, setPhoneNumber ] = useState('');
	const [ message, setMessage ] = useState('');

	const privacyChange = () => {
		setPrivacy(!privacyButton);
		if (privacyButton) {
			$('#sendEmail').prop('disabled', true);
		} else {
			$('#sendEmail').prop('disabled', false);
		}
	};

	const sendEmail = () => {
		const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi;
		// const phoneRegex = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;

		var alerts = {
			missing: [],
			validationErrors: []
		};

		if (!name) {
			alerts.missing.push(t('Name'));
		}
		if (!email) {
			alerts.missing.push(t('Email'));
		}
		// if (!company) {
		// 	alerts.missing.push(t('Company'));
		// }
		if (!phoneNumber) {
			alerts.missing.push(t('Tel'));
		}
		// if (!phoneNumber.match(phoneRegex)) {
		// 	alerts.validationErrors.push(t('InvalidTel'));
		// }
		if (!email.match(emailRegex)) {
			alerts.validationErrors.push(t('InvalidEmail'));
		}

		if (alerts.missing.length > 0 || alerts.validationErrors.length > 0) {
			$('#alerts').addClass('alert-danger').removeClass('alert-success');
			$('#alerts').css('visibility', 'visible');
			$('#alerts').html("");
			if (alerts.validationErrors.length > 0) {
				for (let alert of alerts.validationErrors) {
					$('<span>' + alert + '</span><br>').appendTo('#alerts');
				}

				if (alerts.missing.length > 0) {
					$('<span>' + t('MissingInfo') + ' <br></span>').appendTo('#alerts');
					for (let alert of alerts.missing) {
						$("<span className='alert'>" + alert + '</span><br>').appendTo('#alerts');
					}
				}
			}
		} else {
			$('#alerts').addClass('alert-success').removeClass('alert-danger');
			$('#alerts').css('visibility', 'visible');
			$('#alerts').html(t('EmailSent'));
			axios
				.get(
					`http://localhost:5001/raecom-website/us-central1/sendMail?dest=${email}&name=${name}&company=${company}&phoneNumber=${phoneNumber}&message=${message}`
				)
				.then((result) => {
					console.log("OK");
				});
		}
	};

	return (
		<div className="card" id="ContactCard">
			<div className="card-body">
				<h4 id="alignLeft">{t('Contact')}</h4>
				{/* <p>{t('ContactText3')}</p> */}
				{/* <h4 className="">{t('ContactText2')}</h4> */}
				<form>
					<div className="form-group">
						<div>
							<input
								type="text"
								value={name}
								onChange={(e) => setName(e.target.value)}
								className="form-control"
								id="inputName"
								placeholder={t('Name')}
							/>
						</div>
					</div>
					<div className="form-group">
						<div>
							<input
								type="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								className="form-control"
								id="inputEmail"
								placeholder={t('Email')}
							/>
						</div>
					</div>
					<div className="form-group">
						<div>
							<input
								type="text"
								value={company}
								onChange={(e) => setCompany(e.target.value)}
								className="form-control"
								id="inputCompany"
								placeholder={t('Company')}
							/>
						</div>
					</div>
					<div className="form-group">
						<div>
							<input
								type="tel"
								value={phoneNumber}
								onChange={(e) => setPhoneNumber(e.target.value)}
								className="form-control"
								id="inputPhone"
								placeholder={t('Tel')}
							/>
						</div>
					</div>
					<div className="form-group">
						<div>
							<textarea
								type="tel"
								value={message}
								onChange={(e) => setMessage(e.target.value)}
								className="form-control"
								id="inputPhone"
								placeholder={t('Message')}
							/>
						</div>
					</div>
					<div className="form-group">
						<input type="checkbox" name="privacy" onChange={privacyChange} />
						{t('PrivacyPolicyAgree')}
						<a href="#" data-toggle="modal" data-target="#privacyModal">
							{t('PrivacyPolicy')}
						</a>
					</div>
					<div>
						<button
							disabled={privacyButton}
							type="button"
							id="sendEmail"
							onClick={sendEmail}
							className="btn btn-primary"
						>
							Send
						</button>
					</div>
				</form>
				<div className="" id="alerts" />
			</div>
		</div>
	);
}

function PrivacyPolicy() {
	const language = i18n.language;
	var PrivacyPolicy;
	if (language === 'fi') {
		PrivacyPolicy = (
			<div>
				<div className="modal-header">
					<h4 className="modal-title">
						<p>RAECOM</p>
						<p>Tietosuojaseloste, marketing and customer register privacy policy 30.8.2020</p>
					</h4>
					<button type="button" className="close" data-dismiss="modal">
						&times;
					</button>
				</div>
				<div className="modal-body text-left">
					<dl>
						<dt>1. Rekisterinpitäjä</dt>
						<dd>RAECOM Oy, Y-tunnus: 3006953-5, Ruorikuja 11, 01150 Söderkulla</dd>
						<dt>2. Yhteyshenkilö rekisteriä koskevissa asioissa</dt>
						<dd>Matti Rae</dd>
						<dt>3. Rekisterin nimi ja rekisteröidyt</dt>
						<dd>Raecom Oy:n markkinointi- ja asiakasrekisteri.</dd>
						<dd>
							Rekisterissä käsitellään asiakkaiden sekä potentiaalisten asiakkaiden ja näiden edustajien
							henkilötietoja.
						</dd>
						<dt>4. Henkilötietojen käyttötarkoitus</dt>
						<dd>
							Tietoja käytetään asiakasviestintään ja Raecom Oy:n verkkopalvelun kehittämiseen. Tietoja
							käytetään myös markkinointitarkoituksiin.
						</dd>
						<dd>
							Tietojen käsittelyperusteena on markkinoinnin osalta oikeutettu etu ja asiakassuhteeseen
							perustuvan sopimuksen täytäntöönpano. Suostumuksesi perusteella saat myös sähköisiä
							suoramarkkinointiviestejä.
						</dd>
						<dt>5. Rekisterin tietosisältö</dt>
						<dd>
							Nimi-, puhelin-, sähköposti-, osoite- ja muut jättämäsi tiedot. Evästeitä käytetään
							markkinointiin. Tietoja ei luovuteta kolmansien osapuolten markkinointikäyttöön. Rekisteriin
							voidaan tallentaa seuraavia tietoja:
						</dd>
						<dd>
							<ul>
								<li>Henkilön nimi, sähköpostiosoite ja titteli / vastuualue</li>
								<li>Yrityksen nimi, yhteystiedot</li>
								<li>Asiakkuuden hoitamiseen ja yhteydenpitoon liittyvät tiedot</li>
								<li>Verkkokäyttäytymiseen liittyvät tiedot Raecom Oy:n verkkosivulla</li>
								<li>Markkinointiin ja myynninedistämiseen liittyvät tiedot</li>
								<li>Tekniset tiedot sekä selaimelle lähetetyt evästeet ja niihin liittyvät tiedot.</li>
							</ul>
						</dd>
						<dt>6. Rekisterin tietolähteet</dt>
						<dd>
							Nimi- ja muiden yhteystietojen kerääminen perustuu asiakassuhteeseen tai muuhun yhteyteen
							Raecom Oy:öön. Tietoja rekisteriin kertyy verkkosivuyhteydenoton rekisteröinnistä tai muuten
							asiakkuuden kautta. Tietoja voi kertyä rekisteriin myös julkisista rekistereistä tai
							www-sivuilta. Evästeet ja niiden seuranta on käytössä.
						</dd>
							<dd>Vierailusi Raecom Oy:n sivuilla jättää jäljen, IP-osoitteen.</dd>
						<dt>7. Tietojen luovutus</dt>
						<dd>Tietoja ei luovuteta kolmannelle osapuolelle markkinointitarkoituksiin.</dd>
						<dt>8. Tietojen siirto EU:n tai ETA:n ulkopuolelle</dt>
						<dd>Tietoja ei siirretä EU:n ulkopuolelle.</dd>
						<dt>9. Rekisterin suojaus ja henkilötietojen säilytysaika</dt>
						<dd>
							Tietosi ovat luottamuksellisia. Rekisteri on asianmukaisesti suojattu. Vanhentuneet ja
							tarpeettomat tiedot hävitetään. Henkilötietoja säilytetään vain sen aikaa, kun niitä
							tarvitaan asiakassuhteen hoitamiseen. Lainsäädäntö saattaa edellyttää pidempää
							säilytysaikaa.
						</dd>
						<dt>10. Rekisteröidyn oikeudet</dt>
						<dd>
							Sinulla on lakiin perustuva oikeus milloin tahansa vastustaa henkilötietojesi käsittelyä
							suoramarkkinointitarkoituksiin.
						</dd>
					</dl>
				</div>
				<div className="modal-footer">
					<button type="button" className="btn btn-default" data-dismiss="modal">
						Sulje
					</button>
				</div>
			</div>
		);
	} else {
		PrivacyPolicy = (
			<div>
				<div className="modal-header">
					<h4 className="modal-title">
						<p>RAECOM</p>
						<p>Tietosuojaseloste, marketing and customer register privacy policy 30.8.2020</p>
					</h4>
					<button type="button" className="close" data-dismiss="modal">
						&times;
					</button>
				</div>
				<div className="modal-body text-left">
					<dl>
						<dt>1. Registrar</dt>
						<dd>RAECOM Oy, Y-tunnus: 3006953-5, Ruorikuja 11, 01150 Söderkulla</dd>
						<dt>2. Contact person for matters relating to the Register</dt>
						<dd>Matti Rae</dd>
						<dt>3. Register name and registered data</dt>
						<dd>Raecom Oy:n markkinointi- ja asiakasrekisteri.</dd>
						<dd>Raecom’s marketing and customer register.</dd>
						<dd>
							We handle the personal information of our customers as well as our potential customers and
							their representatives.
						</dd>
						<dt>4. Use of personal data</dt>
						<dd>
							Information is used for customer communication and improvement of Raecom Oy’s web site. In
							addition, the information is used for marketing purposes.
						</dd>
						<dd>
							Processing ground is a legitimate advantage for marketing and implementation of agreement
							based on customer relationship. Upon your consent, you will receive direct electronic
							marketing messages.
						</dd>
						<dt>5. Data content of the register</dt>
						<dd>
							Name, phone, email, address, and other information you submit. Cookies are used in
							marketing. No information for promotional purposes is disclosed to a third party. The
							following information can be stored or registered:
						</dd>
						<dd>
							<ul>
								<li>Name, e-mail address and title / responsibility of the person</li>
								<li>Company name, contact information and industry</li>
								<li>Information regarding customer relationships and contacts </li>
								<li>Web-related information on Raecom Oy’s website</li>
								<li>Marketing and promotional information </li>
								<li>
									Technical information and cookies sent to a registered person’s browser and related
									information{' '}
								</li>
							</ul>
						</dd>
						<dt>6. Information sources of the register</dt>
						<dd>
							Collection of name and other contact information is based on customer relationship or other
							connection to Raecom Oy. Information is collected and registered through customer
							relationship or registering through Raecom Oy’s web site. Information can be collected from
							public sources and address directories.
						</dd>
						<dd>Your visit on Raecom Oy web site leaves information, IP address.</dd>
						<dt>7. Provision of information</dt>
						<dd>No information for promotional purposes is disclosed to a third party.</dd>
						<dt>8. Transfer of data outside the EU or EEA</dt>
						<dd>No information is transferred outside the EU.</dd>
						<dt>9. Protection of the registry and retention of personal data</dt>
						<dd>
							Your information is confidential. The registry is properly protected. Information is stored
							only as long as needed for customer management. Local laws might require storing information
							longer.
						</dd>
						<dt>10. Rights of the registered person</dt>
						<dd>
							You have the right at any time to object to the processing of your personal data for direct
							marketing purposes
						</dd>
					</dl>
				</div>
				<div className="modal-footer">
					<button type="button" className="btn btn-default" data-dismiss="modal">
						Close
					</button>
				</div>
			</div>
		);
	}

	return PrivacyPolicy;
}

function PrivacyPolicyModal() {
	return (
		<div>
			<div id="privacyModal" className="modal fade" role="dialog">
				<div className="modal-dialog modal-lg">
					<div className="modal-content">
						<PrivacyPolicy />
					</div>
				</div>
			</div>
		</div>
	);
}

function AboutCard() {
	const { t } = useTranslation();
	return (
		<div className="card" id="AboutCard">
			<div className="card-body">
				<h4 id="alignLeft" className="card-title">
					{t('About')}
				</h4>
				<div className="container">
					<div className="row">
						<div className="col-2 image-div">
							<img src={raecom} id="AboutImage" alt={t('AboutImage')} />
						</div>
						<div className="col">
							<h5 className="card-text">{t('AboutText1')}</h5>
							<h5 className="card-text">{t('AboutText2')}</h5>
							<h5 className="card-text">{t('AboutText3')}</h5>
							<h5 className="card-text">{t('AboutText4')}</h5>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

function Jumbotron() {
	const { t } = useTranslation();
	return (
		<div className="jumbotron rounded-0">

			<h1 className="display-3">RAECOM OY</h1>
			<p className="lead">{t('AboutText5')}</p>
			<p className="lead">{t('AboutText7')}</p>
			<p className="lead">{t('AboutText6')}</p>
			<p className="lead">{t('AboutText8')}</p>
			<hr className="my-2" />
			
			{/* <p className="lead">
      </p> */}
		</div>
	);
}

function LanguageDropDown() {
	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
	};

	return (
		<div className="dropdown">
				<button
					className="btn btn-primary dropdown-toggle"
					type="button"
					id="dropdownMenuButton"
					data-toggle="dropdown"
					aria-haspopup="true"
					aria-expanded="false"
				>
					Language
				</button>
				<div className="dropdown-menu table-hover" aria-labelledby="dropdownMenuButton">
					<span className="dropdown-item btn" onClick={() => changeLanguage('fi')}>
						Finnish
					</span>
					<span className="dropdown-item btn" onClick={() => changeLanguage('en')}>
						English
					</span>
				</div>
			</div>
	)
}

function App() {
	const { t } = useTranslation();
	return (
		<div className="App">
			<Helmet>
				<title>RAECOM OY</title>
				<meta name="RAECOM main website" content="RAECOM general and contact information" />
			</Helmet>
			<nav className="navbar ">
				<LanguageDropDown />
			</nav>
			<Jumbotron />
			<InfoDeck />
			{/*<ContactCard />*/}
			<h2>{t('Contact')}</h2>
			<a href="mailto:matti.rae@raecom.fi">matti.rae@raecom.fi</a>
			<PrivacyPolicyModal />
		</div>
	);
}

export default App;
