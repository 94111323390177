import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).init({
    resources: {
        en: {
            translations: {
                About: "Entrepreneur Matti Rae",
                AboutText1: "Extensive experience in electrotechnical industry and electrification branch",
                AboutText2: "Specialist in Electric Vehicle Charging",
                AboutText3: "Electrical installations authorization and qualifications: S1",
                AboutText4: "MSc. (EE)",
                AboutText5: "Raecom Oy is an independent consultant specialized in following national and EU-regulation in electrification branch and electric vehicle charging.",
                AboutText6: "",
                AboutText7: "We provide you with an analysis of selected regulatory changes in the business environment ensuring that you can plan ahead for better business.",
                AboutText8: "",
                AboutImage: "CEO of Raecom, Matti Rae",
                Contact: "Contact Us With Email",
                ContactText1: "Message us on WhatsApp",
                ContactText2: "Or send us email",
                ContactText3: "or",
                InvalidTel: "Please check phone number",
                InvalidEmail: "Please check email",
                EmailSent: "Thank you for your interest. We will contact you as soon as possible.",
                Errors: "Errors:",
                MissingInfo: "Missing info: ",
                Name: "Name ",
                Email: "Email ",
                Company: "Company ",
                Tel: "Phone number ",
                Message: "Message ",
                PrivacyPolicy: "Privacy Policy",
                PrivacyPolicyAgree: "I agree to the ",
            }
        },
        fi: {
            translations: {
                About: "Yrittäjä Matti Rae",
                AboutText1: "Pitkä kokemus sähkötarviketeollisuudesta ja sähköistysalasta",
                AboutText2: "Sähköautojen latauksen asiantuntija",
                AboutText3: "Sähköpätevyys S1",
                AboutText4: "Sähköalan DI",
                AboutText5: "Raecom Oy on sähköistysalan regulaation sekä toimintaympäristömuutosten seurannan asiantuntija.",
                AboutText6: "Sähköauton latauskatselmus selvittää kiinteistösi valmiudet sähköauton latausjärjestelmään ja tuottaa selkeän raportin toimenpiteitä varten.",
                AboutText7: "Teemme sähköistysalan toimintaympäristön seurantaa ja vaikutusarvioita. Palvelumme avulla pysyt ajan tasalla ja voit varautua muutoksiin ajoissa.",
                AboutText8: "Sähköauton lataushankesuunnitelma määrittelee latausjärjestelmän kokonaisuutena ja tarjoaa tiedot hankintapäätöksen pohjaksi.",
                AboutImage: "Raecomin toimitusjohtaja, Matti Rae",
                Contact: "Ota yhteyttä sähköpostilla",
                ContactText1: "Lähetä viestiä WhatsAppissa",
                ContactText2: "Tai lähetä sähköpostia",
                ContactText3: "tai",
                InvalidTel: "Tarkista puhelinnumero",
                InvalidEmail: "Tarkista sähköposti",
                EmailSent: "Kiitos viestistänne, otamme yhteyttä mahdollisimman pian",
                Errors: "Virheet: ",
                MissingInfo: "Puuttuvat tiedot: ",
                Name: "Nimi ",
                Email: "Sähköposti ",
                Company: "Yritys ",
                Tel: "Puhelinnumero ",
                Message: "Viesti ",
                PrivacyPolicy: "Tietosuojaselosteen",
                PrivacyPolicyAgree: "Hyväksyn ",
                }
            }
        },
        fallbackLng: "en",
        debug: true,

        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations",

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false, // not needed for react!!
            formatSeparator: ","
        },

        react: {
            wait: true
        }
    });

export default i18n;